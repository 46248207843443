export enum Sites {
  NovoAssist = 2,
  Treviso = 3,
  Inbursa = 4,
  VivaSaude = 5,
  WesternUnion = 6,
}

const useSite = () => {
  if (window.location.href.includes('westernun')) {
    return Sites.WesternUnion
  } else if (window.location.href.includes('inbursa')) {
    return Sites.Inbursa
  } else if (window.location.href.includes('treviso')) {
    return Sites.Treviso
  } else if (window.location.href.includes('vivasaude')) {
    return Sites.VivaSaude
  } else if (window.location.href.includes('novoassist')) {
    return Sites.NovoAssist
  } 
  else {
    return Sites.WesternUnion
  }
}

export const useRootClass = () => {
  const site = useSite()
  if (site === Sites.NovoAssist) {
    return 'na'
  } else if (site === Sites.Treviso) {
    return 'tr'
  } else if (site === Sites.Inbursa) {
    return 'tr'
  } else if (site === Sites.WesternUnion) {
    return 'wu'
  } else {
    return 'wu'
  }
}

export default useSite